import Layout from 'EasyProjectGatsby/components/core/Layout'
import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxStructuredData from 'Shared/components/PuxStructuredData/PuxStructuredData'
import { WidgetPuxSectionType } from 'Shared/components/widgets/WidgetPuxSection'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

export interface HomePageType {
  localization: {
    localizations: localizedPathType[]
  }
}

interface PuxHomePageType {
  data: {
    orchard: {
      puxHomePage: [HomePageType]
    }
  }
  pageContext: {
    pagePath: string
    widgets: {
      widgetZone: [WidgetPuxSectionType]
    }
    metaTags: PuxMetaTagsType
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      puxHomePage(first: 1, where: { path: $pagePath }) {
        localization {
          localizations {
            ... on Orchard_PuxHomePage {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

const PuxHomePage: FunctionComponent<PuxHomePageType> = ({
  data,
  pageContext,
}) => {
  const structuredData = {
    '@context': `https://schema.org`,
    '@type': `WebApplication`,
    name: `Easy Project`,
    offers: {
      '@type': `Offer`,
      price: `39.50`,
      priceCurrency: `USD`,
    },
    applicationCategory: `BusinessApplication`,
    sameAs: [
      `https://www.facebook.com/Easyprojectcz`,
      `https://www.youtube.com/user/easyprojectcz`,
    ],
    image: `https://www.easyproject.com/apple-touch-icon.png`,
  }

  const pageData = data?.orchard?.puxHomePage[0]

  return (
    <Layout localizedPath={pageData.localization?.localizations}>
      <PuxStructuredData structuredData={structuredData} />
      <PuxMetaTags {...pageContext.metaTags} />
      <WidgetBuilder widgetBuilderData={pageContext.widgets.widgetZone} />
    </Layout>
  )
}

export default PuxHomePage
